<template>
  <b-modal id="addProblemSettings-modal" ref="addProblemSettings-modal" scrollable centered hide-footer title="Settings"
    @hidden="closeModal" no-close-on-backdrop>
    <b-form @submit="submit">


      <b-form-group label=" Select allowed languages :">
        <b-form-checkbox-group id="checkbox-group-1"
        class="p-2"
         v-model="form.selectedLang" :options="form.langOptions"
           name="flavour-1" stacked
          :state="langState"
          >
          <b-form-invalid-feedback :state="langState">Please select atleast one Language</b-form-invalid-feedback>
        </b-form-checkbox-group>
      </b-form-group>



      <!-- Submit Button -->
      <b-button pill class="mt-3 btn-layout-2 color-2" block size="lg" type="submit" :disabled="!langState">Add Problem</b-button>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'addProblemSettingsModalComponent',
  props: {
    isVisible: {
      type: Boolean
    }
  },
  data() {
    return this.initialState();
  },
  computed: {
    langState() {
        return this.form.selectedLang.length >= 1
      }
  },
  components: {},
  emits: ['update:isVisible', 'handleSubmit'],
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.$refs['addProblemSettings-modal'].show();
      }
    }
  },
  methods: {
    initialState() {
      return {
        form: {
          selectedLang: ["c", "cpp", "java", "js", "python"],
          langOptions: [
            { text: 'C', value: 'c' },
            { text: 'C++', value: 'cpp' },
            { text: 'Java', value: 'java' },
            { text: 'JavaScript', value: 'js' },
            { text: 'Python', value: 'python' }
          ]
        }
      };
    },
    resetData() {
      Object.assign(this.$data, this.initialState());
    },
    closeModal() {
      this.resetData();
      this.$emit('update:isVisible', false);
    },
    submit(event) {
      event.preventDefault();
      const config = {
        selectedLang: this.form.selectedLang
      };
      this.$emit('handleSubmit', config);
     
    }
  }
};
</script>

<style scoped>
.modal-teams-name-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
}
</style>