<template>
  <div class="page-layout h-100vh bg-primary-gradient">
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div class="w-full" v-if="isAssessmentStarted">
      <!-- Page Header -->
      <div class="timer">
      <p>Time Left: {{ formattedTimer }}</p>
    </div>

      <div>
        <div class="SectionTitle text-center text-xl">{{ interviewData.Title }}</div>
      </div>
      <div class="d-flex my-2 mb-4 align-items-center justify-content-center text-md">
        <div class="">Position :</div>
        <div class="ml-2">{{ interviewData.Position }}</div>
      </div>

      <!-- Problems List as 3D Cards -->
      <div class="codeProblem-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
        <div
          class="codeProblem-card transform transition hover:scale-105"
          v-for="(item, index) in interviewData.problems_Data"
          :key="index"
        >
          <div class="card-content">
            <h3 class="item-title font-semibold w-full" :title="item.ProblemTitle">
              {{ item.ProblemTitle }}
            </h3>
            <div class="w-full d-flex justify-content-between mt-2 align-items-center">
              <span class="difficulty"
              :class="{
                'difficulty-easy': item.ProblemDifficulty === 'Easy',
                'difficulty-medium': item.ProblemDifficulty === 'Medium',
                'difficulty-hard': item.ProblemDifficulty === 'Hard',
              }"
              >
                {{ item.ProblemDifficulty }}
              </span>
              <span 
              :class="{
                  'difficulty-easy': item.Status=='Submitted',
                  'difficulty-hard':  item.Status!='Submitted',
                }"
              >
                {{ item.Status }}
              </span>
            </div>
            <button class="submitButton finishButton px-2 py-2 mr-2 mt-3 w-full" @click="handleStartClick(item)" :disabled="item.Status=='Submitted'">
              Start
            </button>
          </div>
        </div>
      </div>
    </div>

    <DynamicConfirmation :msg="guidelinesMsg" :msg_title="guidelinesTitle" @confirmed="handleStart(item)" />
    <b-modal
      id="error-modal"
      ref="error-modal"
      size="sm"
      button-size="sm"
      header-class="p-2 border-bottom-0"
      footer-class="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title="Oops."
    >
      <p class="my-4">{{ errMsg }}</p>
    </b-modal>

    <b-modal
      id="start-Interview-info-modal"
      ref="error-modal"
      size="md"
      no-close-on-backdrop
      hide-header-close
      buttonSize="md"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-footer
      centered
      title="Assessment Information"
    >
      <p class="my-4">This is a time based Interview you will get  {{ remainingTime/60 }} minutes to complete this assessment. Refreshing or switching tabs will lead to auto submit assessment.</p>
      <b-button class="mt-3" block @click="startAssesment">Start</b-button>
    </b-modal>
  </div>
</template>

<script>
import { getCandidateInterviewDetails,updateCandidateRegistration } from "../api/index";
import { guidelines, guidelineTitle } from "../constants/testMonitoringGuidelines";
import DynamicConfirmation from "../components/Modal/dynamicConfirmation.vue";
export default {
  components: { DynamicConfirmation },
  data() {
    return this.initialState();
  },
  mounted() {
    if(!this.$store.state.assessmentStarted){
       this.$bvModal.show("start-Interview-info-modal");
    }
    this.getCandidateInterviewData();
  },

  computed: {
    formattedTimer() {
      return this.$store.getters.formattedTimer;
    },
    remainingTime() {
      return this.$store.state.timer;
    },
    isAssessmentStarted() {
      return this.$store.state.assessmentStarted;
    },
  },
  watch: {
    // Watch the timer for changes
   async remainingTime(newValue) {
      if (newValue === 0) {
        await this.completeInterview();
        this.navigateToTimeUp();
      }
    },
  },
  methods: {
    initialState() {
      return {
        loading: false,
        loadingMsg: "Please wait...",
        errMsg: "",
        details: {},
        interviewData: [],
        selectedProblemId: 0,
        selectedProblemData: {},
        guidelinesMsg: guidelines || "",
        guidelinesTitle: guidelineTitle || "",
        showProblemDescriptionModal: false,
      };
    },
    getCandidateInterviewData() {
      this.loading = true;
      const payload = {
        InterviewId: this.$route.params.Id,
        CandidateId: this.$store.state.candidateInfo.Id,
      };
      getCandidateInterviewDetails(payload)
        .then((resp) => {
          if (resp && resp.data && resp.data.data) {
            if(resp.data.data?.RegistrationDetails[0]?.Status=="Completed"){
              this.loading = false;
              this.navigateToTimeUp();
            }
            this.interviewData = resp.data.data;
            //set cheating detection in store
            if(this.interviewData?.InterviewConfig){
              const configData= JSON.parse(this.interviewData.InterviewConfig);
              this.$store.commit("setInterviewConfig",configData);
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log("Error", err);
          this.errMsg = err.response.data.error;
          this.$refs["error-modal"].show();
          this.loading = false;
        });
    },
    handleStart() {
      this.$store.commit("setCandidateProblemResId", this.selectedProblemData.candidateResultId);
      this.$bvModal.hide("dynamic-confirmation-modal");
      this.$router.push(`/problems/${this.selectedProblemData.ProblemId}`);
    },
    handleStartClick(item) {
      this.selectedProblemData = item;
      this.$bvModal.show("dynamic-confirmation-modal");
    },

    startAssesment(){
      this.$bvModal.hide("start-Interview-info-modal");
      this.$store.commit("setAssessmentStarted",true);
      this.assessmentStartFlag=true;
      this.startTimer();
    },

    startTimer() {
      this.$store.commit("startTimer");
    },
    stopTimer() {
      this.$store.commit("stopTimer");
    },
    resetTimer() {
      this.$store.commit("resetTimer");
    },
    navigateToTimeUp() {
      this.$router.push("/timesupPage"); // Navigate to the Time-Up page
    },


   async completeInterview() {
      const payload ={
        InterviewId:this.interviewData.Id,
        CandidateId:this.interviewData?.RegistrationDetails[0]?.Id,
        Status:"Completed"
      }
      await updateCandidateRegistration(payload)
        .then((resp) => {
          if (resp) {
            this.loading = false;
          } 
        })
        .catch((err) => {
          console.log("Error",err);
          this.errMsg =err.response.data.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.timer {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 30px;
  color: red;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
}
.codeProblem-list {
  display: grid;
  gap: 1rem;
  padding: 1.5rem;
}

.codeProblem-card {
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transform: perspective(800px) rotateX(0deg) rotateY(0deg);
  transition: all 0.3s ease;
}

.codeProblem-card:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  transform: perspective(800px) rotateX(0deg) rotateY(0deg) scale(1.05);
}

.card-content {
  display: flex;
  flex-direction: column;
}

.item-title {
  font-size: 1.25rem;
  color: #333;
}

.difficulty {
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

</style>
<style>
.h-100vh{
  height: 100vh !important;
}
</style>
