<template>
  <div>
    <b-modal id="createInterview-modal" class="modal_body-lg" centered title="Create Interview" scrollable
      no-close-on-backdrop @close="cancel" size="xl" hide-footer>
      <template #modal-title>
        <div class="">
          <div>Create Interview</div>
        </div>
      </template>
      <div class="modal_body-lg">
        <div v-if="loading" class="d-flex justify-content-center">
          <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
        </div>
        <b-form ref="form" class="form-section" @submit="handleSubmit">
          <div class="">
            <label class="p-2 text-secondary mb-0">Title</label>
            <div class="p-2">
              <b-form-input v-model="formDetails.Title" placeholder="Enter title for Interview" required></b-form-input>
            </div>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Position</label>
            <div class="p-2">
              <b-form-input v-model="formDetails.Position" placeholder="Enter position for Interview"
                required></b-form-input>
            </div>
          </div>
          <div class="">
            <label class="p-2 text-secondary mb-0">Publish Type</label>
            <b-form-group class="p-2">
              <b-button-group toggle class="d-flex">
                <b-button v-for="(option, index) in publishOptions" :key="index" :variant="formDetails.Publish_Type === option.value
                  ? 'primary'
                  : 'secondary'
                  " @click="formDetails.Publish_Type = option.value" class="d-flex align-items-center"
                  :active="formDetails.Publish_Type === option.value">
                  <b-icon :icon="option.icon" class="mr-2"></b-icon>
                  {{ option.text }}
                  <b-icon icon="info-circle" class="ml-2 small-info-icon" :title="option.info"></b-icon>
                </b-button>
              </b-button-group>
            </b-form-group>
          </div>
          <div class="">
            <b-row class="p-2 my-1 mb-3">
              <b-col>
                <label for="input-time">Select Duration (Minutes) :</label>
                <b-form-input id="input-time" v-model="formDetails.timerDuration" type="number" min="1"
                  required></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="">
            <b-row class="p-2 my-1 mb-3">
              <b-col>
                <b-form-checkbox v-model="formDetails.enableCheatingDetection">Enable Cheating
                  Detection</b-form-checkbox>
              </b-col>
            </b-row>
          </div>
          
          <div class="d-flex justify-content-end border-top pt-3">
            <button class="submitButton finishButton" type="submit" @click="handleSubmit">
              Create Interview
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal id="error-modal" ref="error-modal" size="sm" buttonSize="sm" headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0" hide-header-close ok-only centered title="Oops.">
      <p class="my-4">{{ errMsg }}. Please try again</p>
    </b-modal>
  </div>
</template>

<script>
import { createInterview } from "../../api/index";

export default {
  name: "createInterviewModal",
  components: {},
  data() {
    return this.initialState();
  },
  computed: {},
  watch: {},

  methods: {
    initialState() {
      return {
        loading: false,
        submitLoading: false,
        errMsg: "",
        formDetails: {
          Title: "",
          Position: "",
          Publish_Type: "org",
          enableCheatingDetection: true,
          timerDuration:60
        },
        publishOptions: [
          {
            value: "org",
            text: "My Org",
            icon: "people",
            info: "Visible to your organization.",
          },
          {
            value: "private",
            text: "Private",
            icon: "lock",
            info: "Visible only to you.",
          },
        ],
      };
    },

    cancel() {
      Object.assign(this.$data, this.initialState());
      this.$bvModal.hide("createInterview-modal");
    },

    handleSubmit(event) {
      event.preventDefault();
      this.submitLoading = true;
      let config = {
        EnableCheatingDetection: this.formDetails.enableCheatingDetection,
        TimerDuration: this.formDetails.timerDuration
      }
      let payload = {
        Title: this.formDetails.Title,
        Position: this.formDetails.Position,
        Publish_Type: this.formDetails.Publish_Type,
        ConfigDetails: JSON.stringify(config),
        Status: "Open",
        Active_Flag: "Y"
      };
      createInterview(payload)
        .then((resp) => {
          console.log("resp.data.data--,", resp.data);
          this.submitLoading = false;
          this.cancel();
          this.$emit("refreshInterviews");
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.submitLoading = false;
          this.errMsg = err.response.data;
          this.$bvModal.show("error-modal");
        });
    },
  },
};
</script>

<style></style>
