<template>
  <div>
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div>
      <div class="header header-layout-2">
        <div class="header-layout-2-logoSection">
          <img class="header-logoIcon" src="../assets/logoIcon.png" />
          <img class="header-logoImg" src="../assets/loginPageLogo.png" />
        </div>
        <div> <p class="timerText">Time Left: {{ formattedTimer }}</p></div>
        <div v-if="showSubmit" class="ml-auto">
          <button class="submitButton finishButton p-2" @click="submitCode">
            Submit
          </button>
        </div>
      </div>
      <div class="body bg-primary-gradient-1">
        <div class="problems-layout">
          <descriptionlayout :data="problemData" />
          <code-section-layout
            :data="problemData"
            @language-change="updateLanguage"
            @code-change="updateCode"
          />
        </div>
      </div>
    </div>

    <b-modal
      id="submitted-info-modal"
      ref="error-modal"
      size="sm"
      no-close-on-backdrop
      hide-header-close
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      @ok="goBack"
      ok-only
      centered
      title="Information"
    >
      <p class="my-4">You have submitted this problem.</p>
    </b-modal>
  </div>
</template>

<script>
import { getCandidateProblemDetails, submitCodeProblem, updateCandidateRegistration } from "../api/index";
import CodeSectionLayout from "../components/CodeProblems/CodeSectionLayout/CodeSectionLayout.vue";
import Descriptionlayout from "../components/CodeProblems/Description/Descriptionlayout.vue";
export default {
  components: { Descriptionlayout, CodeSectionLayout },
  data() {
    return this.initialState();
  },
  mounted() {
    this.getProblemData();

    // Event listeners
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    window.addEventListener("resize", this.handleResize);

    // Listen to window focus/blur events to detect when the window gains or loses focus
    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("blur", this.handleBlur);
  },
  beforeDestroy() {
    // Cleanup event listeners
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    window.removeEventListener("resize", this.handleResize);

    window.removeEventListener("focus", this.handleFocus);
    window.removeEventListener("blur", this.handleBlur);
  },
  computed: {
    showSubmit() {
      return this.problemData
        ? this.problemData.candidateProblemDetails?.Status != "Submitted"
        : false;
    },
    formattedTimer() {
      return this.$store.getters.formattedTimer;
    },
    remainingTime() {
      return this.$store.state.timer;
    },
  },
  watch: {
    // Watch the timer for changes
    async remainingTime(newValue) {
      if (newValue === 0) {
        this.timesupFlag = true;
        await this.submitCode();
        await this.completeInterview();
        this.navigateToTimeUp();
      }
    },
  },
  methods: {
    initialState() {
      return {
        loading: false,
        loadingMsg: "Please wait...",
        code: "",
        language: "java",
        errMsg: "",
        problemData: "",
        isTabActive: true, // Track if the tab is active
        windowSizeChanged: false, // Track if the window size changes (minimizing or resizing)
        isWindowFocused: true, // Track if the window size has changed
        timesupFlag:false,
        interviewConfig:this.$store.state.InterviewConfig, 
        enableCheatingDetection:this.$store.state.InterviewConfig?.EnableCheatingDetection
      };
    },
    getProblemData() {
      this.loading = true;
      let payload = {
        problem_Id: this.$route.params.Id,
        InterviewId: this.$store.state.InterviewId,
        CandidateId: this.$store.state.candidateInfo.Id,
      };
      getCandidateProblemDetails(payload)
        .then((resp) => {
          this.problemData = resp.data.data;
          //set allowed languages dynamically
          if(this.problemData?.ProblemConfig){
            const langConfig =JSON.parse(this.problemData.ProblemConfig);
            if(langConfig?.selectedLang){
              this.language=langConfig?.selectedLang[0];
            }
          }
          this.loading = false;
          if (
            this.problemData?.candidateProblemDetails?.Status == "Submitted"
          ) {
            this.$bvModal.show("submitted-info-modal");
          }
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    async submitCode() {
      this.loading = true;
      const payload = {
        code: this.code,
        language: this.language,
        problemId: this.$route.params.Id,
        InterviewId: this.$store.state.InterviewId,
        CandidateId: this.$store.state.candidateInfo.Id,
        CandidateProblemResId: this.$store.state.CandidateProblemResId,
      };
      await submitCodeProblem(payload)
        .then((resp) => {
          console.log(resp);
          if(!this.timesupFlag)
        {this.goBack();}          
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.errMsg = err.response.data;
          this.loading = false;
        });
    },
    updateLanguage(language) {
      this.language = language;
    },
    updateCode(code) {
      this.code = code;
    },

    async handleVisibilityChange() {
      // The document.hidden property is true when the tab is not visible
      this.isTabActive = !document.hidden;
      console.log(this.isTabActive ? "Tab is active" : "Tab is inactive");

      if(this.enableCheatingDetection && !this.isTabActive && !this.loading){
        await this.submitCode();
      }
    },
    async handleResize() {
      // Detect window resize event (this could be used to track minimization)
      this.windowSizeChanged = true;
      if(this.enableCheatingDetection && !this.loading){
        await this.submitCode();
      }
      
      console.log("Window size changed");
      // setTimeout(() => {
      //   // Reset window size change after a brief delay to prevent continuous updates
      //   this.windowSizeChanged = false;
      // }, 500);
    },
    handleFocus() {
      // The window has gained focus (could be on any monitor)
      this.isWindowFocused = true;
      console.log("Window is focused");
    },
    async handleBlur() {
      // The window has lost focus (could be on any monitor)
      this.isWindowFocused = false;
      if(this.enableCheatingDetection && !this.loading){
        await this.submitCode();
      }
      console.log("Window is not focused");
    },
    navigateToTimeUp() {
      this.$router.push("/timesupPage"); // Navigate to the Time-Up page
    },
    async completeInterview() {
      const payload ={
        InterviewId: this.$store.state.InterviewId,
        CandidateId: this.$store.state.candidateInfo.Id,
        Status:"Completed"
      }
      await updateCandidateRegistration(payload)
        .then((resp) => {
          if (resp) {
            this.loading = false;
          } 
        })
        .catch((err) => {
          console.log("Error",err);
          this.errMsg =err.response.data.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.problems-layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 12px;
}
.problems-codeSection-layout {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  gap: 8px;
}
.bg-primary-gradient-1 {
  background: linear-gradient(145deg, #390879, #240551); /* Theme gradient */
}
</style>
<style scoped>
.timerText{
  text-align: center;
  color: red;
  font-weight: bold;
  margin: auto;
}
</style>
