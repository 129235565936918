import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

const getDefaultState = () => {
    return {
        playerName: "",
        gameCode: false,
        superAdmin: false,
        questionLength: 0,
        api_Url: process.env.VUE_APP_SERVER_ENDPOINT + "/",
        eventData: {},

        first_name: "",
        last_name: "",
        email: "",
        org_name: "",
        isLoggedIn: false,
        accessToken: "",

        skipAuth: false,
        userInfo: "",

        gameConfig: {
            enableTimer: false,
            default_timer_time: 0,
            isTimerActive: false,
            enableTeamMode: false,
            teamCount: 2,
            teams: []
        },

        candidateInfo: {
            name: "",
            email: "",
            token: "",
            candidateFlg: false,
            Id: "",
        },
        userGameConfig: {},
        InterviewId: "",
        InterviewConfig:false,// Default to interview Config
        CandidateProblemResId: "",

        totalInterviewTime: process.env.VUE_APP_TIMER_DURATION * 60, // Default to 60 mins if env variable is not set
        timer: process.env.VUE_APP_TIMER_DURATION * 60, // Default to 60 mins if env variable is not set
        assessmentStarted: false, // Default to 60 mins if env variable is not set
        intervalId: null,
    }
}

export default new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        setEventData(state, data) {
            state.eventData = data;
        },
        setInterviewId(state, data) {
            state.InterviewId = data;
        },
        setCandidateProblemResId(state, data) {
            state.CandidateProblemResId = data;
        },
        setGameConfig(state, data) {
            const gameConfig = {
                enableTimer: data.enableTimer || false,
                default_timer_time: data.default_timer_time || 0,
                isTimerActive: data.isTimerActive || false,
                enableTeamMode: data.enableTeamMode || false,
                teamCount: data.teamCount || 2,
                teams: data.teams || []
            };
            state.gameConfig = gameConfig;
        },
        setGameCode(state, data) {
            state.gameCode = data;
        },
        setSkipAuth(state, data) {
            state.skipAuth = data;
        },
        setQuestionLength(state, data) {
            state.questionLength = data;
        },
        setAccessToken(state, data) {
            state.accessToken = data;
        },
        setPlayerName(state, data) {
            console.log("setPlayerName", data)
            state.playerName = data;
        },
        setLoggedInUserInfo(state, data) {
            state.first_name = data.First_Name;
            state.last_name = data.Last_Name;
            state.email = data.Email;
            state.org_name = data.Org_Name;
            state.isLoggedIn = true;
            state.accessToken = data.Token;
            state.playerName = data.First_Name + " " + data.Last_Name;
            state.userInfo = data;
        },
        setLoggedInCandidteInfo(state, data) {
            state.candidateInfo.Id = data.Id;
            state.candidateInfo.candidateFlg = true;
            state.candidateInfo.name = data.User_Name;
            state.candidateInfo.email = data.Email;
            state.candidateInfo.token = data.Token;
        },
        setUserGameConfig(state, data) {
            state.userGameConfig = data;
        },
        clearState(state) {
            // Reset the state to the default state
            Object.assign(state, getDefaultState());
        },



        setAssessmentStarted(state, data) {
            state.assessmentStarted = data;
        },

        startTimer(state) {
            if (state.intervalId) return; // Prevent multiple intervals
            
            state.intervalId = setInterval(() => {
                if (state.timer > 0) {
                    state.timer -= 1;
                } else {
                    clearInterval(state.intervalId);
                    state.intervalId = null;
                }
            }, 1000);
        },
        stopTimer(state) {
            clearInterval(state.intervalId);
            state.intervalId = null;
        },
        resetTimer(state) {
            state.timer = state.totalInterviewTime * 60,
            clearInterval(state.intervalId);
            state.intervalId = null;
        },
        setInterviewConfig(state, data) {
            if(data){
                state.InterviewConfig = data;
                if(data?.TimerDuration){
                    state.totalInterviewTime = data.TimerDuration * 60;
                    state.timer = data.TimerDuration * 60;
                }
            }
        },
    },
    getters: {
        formattedTimer(state) {
            const minutes = Math.floor(state.timer / 60);
            const seconds = state.timer % 60;
            return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        },
        timerInSeconds(state) {
            return state.timer;
        }
    },
    actions: {},
    modules: {},
    plugins: [vuexLocal.plugin],
});