<template>
  <div class="problem-container">
    <div class="problem-container-header">
      Code
      <div v-if="code && showRun" class="run-code-btn" @click="runCode">Run</div>
    </div>
    <div class="problem-container-body">
      <div class="code-section">
        <div class="px-2">
          <select v-model="language" @change="handleLanguageChange" id="language" class="border-0 text-bold">
            <option v-for="i in langOptions" :key="i" :value="i">{{ getLanguageDisplayName(i) }}</option>
          </select>
        </div>
        <div class="code-input-container">
          <b-form-textarea class="code-input" id="textarea" v-model="code" @input="emitCodeChange" @copy.prevent
            @cut.prevent @paste.prevent></b-form-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    loading: Boolean,
  },
  data() {
    return this.initialState();
  },

  watch: {
    data(newValue) {
      this.problemData = newValue;
      //set allowed languages dynamically
      if (this.problemData?.ProblemConfig) {
        const langConfig = JSON.parse(this.problemData.ProblemConfig);
        if(langConfig?.selectedLang){
          this.language = langConfig?.selectedLang[0];
          this.langOptions = langConfig?.selectedLang;
        }
      }
      this.code = JSON.parse(newValue.CodeSnippets)[this.language];
      this.emitCodeChange();
    },
  },
  computed: {
    showRun() {
      return (this.problemData) ? this.problemData.candidateProblemDetails?.Status != "Submitted" : false
    }
  },
  methods: {
    initialState() {
      return {
        language: "java",
        code: "",
        problemData: "",
        langOptions: ["c", "cpp", "java", "js", "python"],
      };
    },
    handleLanguageChange() {
      this.code = JSON.parse(this.problemData.CodeSnippets)[this.language];
      this.emitLanguageChange();
      this.emitCodeChange();
    },
    emitLanguageChange() {
      this.$emit('language-change', this.language);
    },
    emitCodeChange() {
      this.$emit('code-change', this.code);
    },
    getLanguageDisplayName(lang) {
      const languageMap = {
        c: "C",
        cpp: "C++",
        java: "Java",
        js: "JavaScript",
        python: "Python",
      };
      return languageMap[lang] || lang; // Default to lang if not found
    },
    runCode() {
      if (!this.loading) {
        let payload = {
          code: this.code,
          language: this.language,
          problemId: this.problemData.ProblemId
        };
        this.$emit("run", payload);
      }
    },
  },
};
</script>

<style src="./CodeView.css"></style>
