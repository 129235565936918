<template>
  <div class="page-layout">
    <BlockUI v-if="loading" :message="loadingMsg">
      <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
    </BlockUI>
    <div v-else class="w-full">
      <div class="my-2">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center" @click="goBack">
            <b-icon icon="arrow-left" scale="1.5"></b-icon>
          </div>
          <button
            class="submitButton addButton"
            @click="showImportProblemModal"
          >
            Add Problems
          </button>
        </div>
      </div>
      <!-- Page--body -->
      <div class="d-flex justify-content-between align-items-center">
        <div class="SectionTitle">{{ interviewData.Title }}</div>
        <div class="d-flex">
          <div>
            <div
              v-if="interviewData.Active_Flag == 'Y'"
              @click="updateStatus('N')"
            >
              <b-icon
                id="deactivate-interview"
                icon="power"
                class="rounded-circle bg-danger p-1"
                font-scale="1.5"
                variant="light"
              ></b-icon>
              <b-tooltip
                target="deactivate-interview"
                triggers="hover"
                placement="bottom"
              >
                Deactivate Interview
              </b-tooltip>
            </div>
            <div
              v-if="interviewData.Active_Flag == 'N'"
               @click="updateStatus('Y')"
            >
              <b-icon
                id="activate-interview"
                icon="power"
                class="rounded-circle bg-success p-1"
                font-scale="1.5"
                variant="light"
              ></b-icon>
              <b-tooltip
                target="activate-interview"
                triggers="hover"
                placement="bottom"
              >
                Activate Interview
              </b-tooltip>
            </div>
          </div>
          <div @click="downloadResults" class="ml-3">
            <b-icon
              id="export-results"
              icon="file-earmark-spreadsheet-fill"
              font-scale="1.5"
              class="color-primary"
            ></b-icon>
            <b-tooltip
              target="export-results"
              triggers="hover"
              placement="bottom"
            >
              Export Results
            </b-tooltip>
          </div>
        </div>
      </div>
      <div class="d-flex my-2 align-items-center">
        <div class="">Position :</div>
        <div class="ml-2">{{ interviewData.Position }}</div>
      </div>
      <div class="d-flex my-2 align-items-center">
        <div class="">Status :</div>
        <div
          class="ml-2 text-bold"
          :class="{
            'difficulty-easy': interviewData.Active_Flag == 'Y',
            'difficulty-hard': interviewData.Active_Flag != 'Y',
          }"
        >
          {{ interviewData.Active_Flag == "Y" ? "Active" : "Inactive" }}
        </div>
      </div>
      <div class="d-flex my-2 align-items-center">
        <div class="">Interview Invite Link :</div>
        <button
          class="submitButton finishButton px-3 py-1 text-sm"
          @click="copyLink"
          :title="inviteLink"
        >
          {{ copyLinkText }}
        </button>
      </div>

      <div class="mt-3">
        <b-tabs v-model="activeTab">
          <b-tab title="Details" lazy :title-link-class="'m-0'">
            <div v-if="activeTab == 0" class="codeProblem-list">
              <div
                class="codeProblem-item-row"
                v-for="(item, index) in interviewData.problems_Data"
                :key="index"
                @click="handleProblemClick(item)"
              >
                <div class="item-title pl-2 pr-4" :title="item.Title">
                  {{ item.ProblemTitle }}
                </div>
                <div class="flex-3">
                  {{ item.CreatedBy }}
                </div>
                <div class="flex-3">
                  {{ item.ProblemDifficulty }}
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Results" lazy :title-link-class="'m-0'"
            ><results-table />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <import-codeproblems @handleAddProblem="handleAddProblem" />
    <code-problem-details
      :problem_Id="selectedProblemId"
      :showProblemDescription="showProblemDescriptionModal"
      @handleClose="showProblemDescriptionModal = false"
    />
  </div>
</template>

<script>
import ImportCodeproblems from "../components/Modal/importCodeproblems.vue";
import {
  getInterviewDetails,
  addInterviewProblem,
  getInterviewResults,
  updateInterview
} from "../api/index";
import ResultsTable from "../components/TmInterview/ResultsTable.vue";
import CodeProblemDetails from "../components/Modal/codeProblemDetails.vue";
import { exportResults } from "../actions/TMInterview/index.js";
export default {
  components: { ImportCodeproblems, ResultsTable, CodeProblemDetails },
  data() {
    return this.initialState();
  },
  mounted() {
    this.getInterviewData();
  },
  computed: {
    inviteLink() {
      const domain = window.location.origin; // Get the current domain
      const InterviewId = this.interviewData.InterviewId;
      return `${domain}/tmInterview/${InterviewId}`; // Construct the dynamic invite link
    },
  },
  methods: {
    initialState() {
      return {
        loading: false,
        loadingMsg: "Please wait...",
        details: {},
        interviewData: [],
        interviewResultsData: [],
        selectedProblemId: "",
        showProblemDescriptionModal: false,
        activeTab: 0,
        //copy link
        copyLinkText: "Copy Link",
      };
    },
    copyLink() {
      navigator.clipboard.writeText(this.inviteLink).then(() => {
        this.copyLinkText = "Copied";
        setTimeout(() => {
          this.copyLinkText = "Copy Link";
        }, 2000);
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    showImportProblemModal() {
      this.$bvModal.show("importProblem-modal");
    },
    handleProblemClick(item) {
      this.selectedProblemId = item.ProblemId;
      this.showProblemDescriptionModal = true;
    },
    handleAddProblem(item) {
      this.loading = true;
      let payload = {
        InterviewId: this.$route.params.Id,
        ProblemId: item.ProblemId,
        ConfigDetails: JSON.stringify(item.problemConfig),
        Active_Flag: "Y",
      };
      addInterviewProblem(payload)
        .then((resp) => {
          console.log(resp?.data?.message);
          this.loading = false;
          this.$bvModal.hide("importProblem-modal");
          this.getInterviewData();
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    getInterviewData() {
      this.loading = true;
      let payload = {
        Id: this.$route.params.Id,
      };
      getInterviewDetails(payload)
        .then((resp) => {
          this.interviewData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },

    downloadResults() {
      this.loading = true;
      let payload = {
        Id: this.$route.params.Id,
      };
      getInterviewResults(payload)
        .then(async (resp) => {
          const results = resp.data.data;
          await exportResults(results);
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
    updateStatus(Status) {
      this.loading = true;
      let payload = {
        Id: this.$route.params.Id,
        Active_Flg: Status,
      };
      updateInterview(payload)
        .then((resp) => {
          console.log(resp.data.message);
          this.getInterviewData();
          this.loading = false;
        })
        .catch((err) => {
          console.log("Error", err?.response?.data);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
