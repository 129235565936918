<template>
  <div>
    <b-modal
      id="importProblem-modal"
      class="modal_body-lg"
      centered
      title="Create Interview"
      scrollable
      no-close-on-backdrop
      @close="cancel"
      size="xl"
      hide-footer
    >
      <template #modal-title>
        <div class="">
          <div>Import Problems</div>
        </div>
      </template>
      <div class="modal_body-lg">
        <b-tabs v-model="activeTab">
          <b-tab title="Owned Problems" lazy :title-link-class="'m-0'">
            <owned-problems-table :showAddBtn="true" @handleAdd="handleAddProblemClick"/>
          </b-tab>
          <b-tab title="Library" lazy :title-link-class="'m-0'">
            <problems-table :showAddBtn="true" @handleAdd="handleAddProblemClick"
          />
        </b-tab>
        </b-tabs>
      </div>
    </b-modal>
    <b-modal
      id="error-modal"
      ref="error-modal"
      size="sm"
      buttonSize="sm"
      headerClass="p-2 border-bottom-0"
      footerClass="p-2 border-top-0"
      hide-header-close
      ok-only
      centered
      title="Oops."
    >
      <p class="my-4">{{ errMsg }}. Please try again</p>
    </b-modal>
    <AddProblemSettings :isVisible="showSetting" @update:isVisible="showSetting = $event"  @handleSubmit="handleAddProblem" />
  </div>
</template>

<script>
import OwnedProblemsTable from '../TmInterview/OwnedProblemsTable.vue';
import ProblemsTable from "../TmInterview/problemsTable.vue";
import AddProblemSettings from './addProblemSettings.vue';
export default {
  name: "importProblemModal",
  components: { ProblemsTable, OwnedProblemsTable,AddProblemSettings },
  data() {
    return this.initialState();
  },
  computed: {},
  watch: {},

  methods: {
    initialState() {
      return {
        loading: false,
        submitLoading: false,
        selectedItem:false,
        showSetting:false,
        errMsg: "",
      };
    },

    cancel() {
      Object.assign(this.$data, this.initialState());
      this.$bvModal.hide("importProblem-modal");
    },
    handleAddProblemClick(item) {
      this.selectedItem = item;
      this.showSetting = true;
    },
    handleAddProblem(problemConfig) {
      this.selectedItem.problemConfig = problemConfig;
      this.showSetting = false;
      this.$bvModal.hide("addProblemSettings-modal");
      this.$emit("handleAddProblem", this.selectedItem);
    },
  },
};
</script>

<style></style>
